<template>
  <section>
    <v-list class="w-100 pt-0 mt-auto" light>

      <div class="d-flex my-2">
        <span class="ms-0 me-3">{{$t('Amount')}}</span>
        <v-divider></v-divider>
      </div>

      <v-list-item link ripple @click="openAmountBottomSheet" class="px-0 mx-0">
        <v-list-item-icon>
          <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
            class="d-flex align-items-center justify-content-center rounded-lg">
            <iconly type="bulk" name="edit" color="#7E55F3" />
          </v-sheet>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold f14">{{ $t('Counseling') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-subtitle v-if="doctor.consultantAmount && doctor.consultantAmount > 0" class="font-weight-bold f13 primary--text">{{ doctor.consultantAmount | VMask(currencyMask) }} {{ $t('RIAL') }}</v-list-item-subtitle>
          <v-list-item-subtitle v-else class="font-weight-bold f13 grey--text">{{ $t('Unregistered') }}</v-list-item-subtitle>
        </v-list-item-action>
      </v-list-item>

      <div class="d-flex my-2">
        <span class="ms-0 me-3">{{$t('Wallet')}}</span>
        <v-divider></v-divider>
      </div>

      <v-list-item link ripple @click="$refs.ibanBottomSheet.open()" class="px-0 mx-0">
        <v-list-item-icon>
          <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
            class="d-flex align-items-center justify-content-center rounded-lg">
            <iconly type="bulk" name="edit" color="#7E55F3" />
          </v-sheet>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold f14">{{ $t('IBAN') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-subtitle v-if="iban && iban != 'not defined' && iban.startsWith('IR')"
                                class="font-weight-bold f13">{{ iban }}</v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="iban && iban != 'not defined' && !iban.startsWith('IR')"
                                class="font-weight-bold f13 primary--text">IR{{ iban }}</v-list-item-subtitle>
          <v-list-item-subtitle v-else class="font-weight-bold f13 grey--text">{{ $t('Unregistered') }}</v-list-item-subtitle>
        </v-list-item-action>
      </v-list-item>

      <v-list-item link ripple to="/s/financial/transactions" href="/s/financial/transactions" class="px-0 mx-0">
        <v-list-item-icon>
          <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
            class="d-flex align-items-center justify-content-center rounded-lg">
            <iconly type="bulk" name="edit" color="#7E55F3" />
          </v-sheet>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold f14">{{ $t('Transactions') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <iconly type="light" name="arrow-left2" color="#757575" />
        </v-list-item-action>
      </v-list-item>

      <v-list-item link ripple to="/s/financial/settlements" href="/s/financial/settlements" class="px-0 mx-0">
        <v-list-item-icon>
          <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
            class="d-flex align-items-center justify-content-center rounded-lg">
            <iconly type="bulk" name="edit" color="#7E55F3" />
          </v-sheet>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold f14">{{ $t('Settlement requests') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <iconly type="light" name="arrow-left2" color="#757575" />
        </v-list-item-action>
      </v-list-item>

    </v-list>


    <vue-bottom-sheet ref="amountBottomSheet">
      <section class="d-flex flex-column   justify-content-center">
        <span class="my-1 d-block text-center f14">{{ $t('Consultation amount') }}</span>
        
        <span class="my-1 d-block f13">{{ $t('The cost of each one-hour session') }}</span>
        <v-text-field 
          v-mask="currencyMask"
          class="text-center" 
          outlined 
          single-line
          suffix="ریال"
          v-model="consultantAmount" 
          :key="keyAmount"
          :placeholder="$t('Cost')" 
          type="text" 
          :rules="[(v) => !!v]" 
          required
        />

        <span class="my-1 d-block f13">{{ $t('Payment rules') }}</span>
        <v-radio-group v-model="consultantPaymentType" mandatory color="primary" column class="w-100">
            <v-radio
            :label="$t('Payment before the session')"
            ripple
            value="BEFORE_START_SESSION"
            class="mb-2"
          ></v-radio>

          <v-radio
            :label="$t('Payment after the session')"
            value="AFTER_START_SESSION"
            ripple
            class="mt-2"
          ></v-radio>
        </v-radio-group>

        <div class="my-2 w-100">
          <v-btn block color="primary" @click="submitAmount" rounded>{{ $t('Submit') }}</v-btn>
        </div>
      </section>
    </vue-bottom-sheet>

    <vue-bottom-sheet ref="ibanBottomSheet" @closed="onCloseIbanBottomSheet">
      <section class="d-flex flex-column justify-content-center">
        <span class="my-1 d-block text-center f14 fw-bold">{{ $t('IBAN registration') }}</span>
        
        <span class="my-1 d-block fw-bold f13">{{ $t('Description') }}</span>
        <span class="my-1 d-block f12">{{ $t('IBAN must be the same as the first and last name registered in the profile') }}</span>


        <span class="my-1 d-block f13">{{ $t('IBAN') }}</span>
        <v-text-field
          class="text-center ltr"
          type="number"
          outlined
          hide-spin-buttons
          single-line
          placeholder="XXXXXXXXXXXXXXXXXXXXXX"
          :rules="[(v) => !!v]"
          v-model="iban"
          :key="keyIban"
          counter="24"
          prefix="IR"
          required
          
        />


        <span class="mb-1 d-block f13">{{ $t('First and last name') }}</span>
        <v-text-field
          class="text-center"
          outlined
          single-line
          v-model="fullName"
          readonly
          hide-details
        />
        
        <span class="mb-1 mt-3 d-block f13">{{ $t('Bank') }}</span>
        <v-text-field
          class="text-center"
          outlined
          single-line
          v-model="tvBank"
          readonly
          hide-details
        >
          <template slot="append">
            <img :src="require(`@/assets/images/bank-icons/${ivBank}.png`)" width="28" height="28"/>
          </template>
        </v-text-field>

        <div class="my-4 w-100">
          <v-btn block color="primary" @click="submitIban" rounded>{{ $t('Submit') }}</v-btn>
        </div>
      </section>
    </vue-bottom-sheet>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

  const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });


export default {
  data() {
    return {
      currencyMask,
      doctor: {},
      iban: "not defined",
      keyIban: 0,

      consultantPaymentType: "BEFORE_START_SESSION",
      consultantAmount: '',
      keyAmount: 0,

      fullName: '',
      ivBank: 'error',
      tvBank: '---',
      ibanSubmited: false,
      accountBalance:{},
    }
  },
  methods: {
    openAmountBottomSheet(){
      this.$refs.amountBottomSheet.open()
      this.consultantAmount = this.doctor.consultantAmount;
      this.keyAmount++;
    },
    getDoctorAccountBalance() {
      apiService.getDoctorAccountBalance()
        .then((response) => {
          this.accountBalance = response.data;
          this.iban = response.data.iban
        })
        .catch((err) => {
          this.$swal({
                icon: "warning",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t('OK'),
              });
        });
    },
    getDoctorInfoByMyMobile() {
      apiService.getDoctorInfoByMyMobile()
        .then((response) => {
          this.doctor = response.data;
          this.fullName = this.doctor.firstname + " " + this.doctor.lastname;
          this.consultantAmount = this.doctor.consultantAmount;
          this.consultantPaymentType = this.doctor.consultantPaymentType;
          this.keyAmount++;
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitAmount(){
      if (this.consultantAmount.replace(/,/g, '').length > 0) {
        apiService.updateConsultant(this.consultantAmount.replace(/,/g, ''), this.consultantPaymentType)
        .then(() => {
          this.$refs.amountBottomSheet.close();
          this.doctor.consultantAmount = this.consultantAmount.replace(/,/g, '');
        })
        .catch((err) => {
          this.$refs.amountBottomSheet.close();
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
      }
    },
    onCloseIbanBottomSheet(){
      if(!this.ibanSubmited) {
        this.iban = this.accountBalance.iban;
      }
    },
    submitIban(){
      if(this.isValidIbanNumber('IR' + this.iban)) {
        apiService.addDoctorIban(this.iban)
          .then(() => {
            this.ibanSubmited = true;
            this.$refs.ibanBottomSheet.close();
            
          })
          .catch((err) => {
            this.$refs.ibanBottomSheet.close();
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
      }
      else {
        this.$refs.ibanBottomSheet.close();
        this.$swal({
          icon: "warning",
          text: this.$t("IBAN is invalid"),
          confirmButtonText: this.$t('OK'),
        });
      }
    },
    checkIban(txt) {
      if (txt.startsWith("IR")) {
        txt = txt.replace("IR", "");
      }

      if (txt.length < 6) {
        this.ivBank = "error"; // آیکون بانک
        this.tvBank = "---";
      }

      if (txt.length >= 5) {
        switch (txt.slice(2, 5).toString()) {
          case "015"://سپه
            this.ivBank = "sepah";
            this.tvBank = this.$t("Sepah");
            break;
          case "058"://سرمایه
            this.ivBank = "sarmayeh";
            this.tvBank = this.$t("Sarmayeh");
            break;
          case "019"://صادرات
            this.ivBank = "saderat";
            this.tvBank = this.$t("Saderat");
            break;
          case "011"://صنعت و معدن
            this.ivBank = "sanat_madan";
            this.tvBank = this.$t("Sanat-o-madan");
            break;
          case "053"://کارآفرین
            this.ivBank = "karafarin";
            this.tvBank = this.$t("Karafarin");
            break;
          case "016"://کشاورزی
            this.ivBank = "keshavarzi";
            this.tvBank = this.$t("Keshavarzi");
            break;
          case "010"://بانک مرکزی
            this.ivBank = "markazi";
            this.tvBank = this.$t("Markazi Bank");
            break;
          case "014"://مسکن
            this.ivBank = "maskan";
            this.tvBank = this.$t("Maskan");
            break;
          case "012"://ملت
            this.ivBank = "mellat";
            this.tvBank = this.$t("Mellat");
            break;
          case "062"://آینده
            this.ivBank = "ayandeh";
            this.tvBank = this.$t("Ayandeh");
            break;
          case "055"://اقتصاد نوین
            this.ivBank = "eghtesad_novin";
            this.tvBank = this.$t("Eghtesad-novin");
            break;
          case "054"://پارسیان
            this.ivBank = "parsian";
            this.tvBank = this.$t("Parsian");
            break;
          case "057"://پاسارگاد
            this.ivBank = "pasargad";
            this.tvBank = this.$t("Pasargad");
            break;
          case "021"://پست بانک
            this.ivBank = "postbank";
            this.tvBank = this.$t("Post-bank");
            break;
          case "018"://تجارت
            this.ivBank = "tejarat";
            this.tvBank = this.$t("Tejarat");
            break;
          case "051"://توسعه
            this.ivBank = "toseh";
            this.tvBank = this.$t("Toseh");
            break;
          case "020"://توسعه صادرات
            this.ivBank = "toseh_saderat";
            this.tvBank = this.$t("Toseh-saderat");
            break;
          case "013"://رفاه
            this.ivBank = "refah";
            this.tvBank = this.$t("Refah");
            break;
          case "056"://سامان
            this.ivBank = "saman";
            this.tvBank = this.$t("Saman");
            break;
          case "017"://ملی
            this.ivBank = "melli";
            this.tvBank = this.$t("Melli");
            break;
          case "063"://انصار
            this.ivBank = "ansar";
            this.tvBank = this.$t("Ansar");
            break;
          case "022"://توسعه تعاون
            this.ivBank = "toseh_tavon";
            this.tvBank = this.$t("Toseh-tavon");
            break;
          case "066"://دی
            this.ivBank = "dey";
            this.tvBank = this.$t("Dey");
            break;
          case "052"://قوامین
            this.ivBank = "ghavamin";
            this.tvBank = this.$t("Ghavamin");
            break;
          case "064"://گردشگری
            this.ivBank = "gardeshgari";
            this.tvBank = this.$t("Gardeshgari");
            break;
          case "065"://حکمت ایرانیان
            this.ivBank = "hekmat";
            this.tvBank = this.$t("Hekmat-iranian");
            break;
          case "070"://رسالت
            this.ivBank = "resalat";
            this.tvBank = this.$t("Resalat");
            break;
          case "069"://ایران زمین
            this.ivBank = "iranzamin";
            this.tvBank = this.$t("Iran-zamin");
            break;
          case "059"://سینا
            this.ivBank = "sina";
            this.tvBank = this.$t("Sina");
            break;
          case "061"://شهر
            this.ivBank = "shahr";
            this.tvBank = this.$t("Shahr");
            break;
          case "078"://خاورمیانه
            this.ivBank = "khavarmianeh";
            this.tvBank = this.$t("Khavarmianeh");
            break;
          case "060"://مهر ایران
            this.ivBank = "mehr";
            this.tvBank = this.$t("Mehr-iran");
            break;
          case "075"://ملل
            this.ivBank = "melal";
            this.tvBank = this.$t("Melal");
            break;
          case "073"://کوثر
            this.ivBank = "kosar";
            this.tvBank = this.$t("Kosar");
            break;
          case "080"://نور
            this.ivBank = "noor";
            this.tvBank = this.$t("Noor");
            break;
          default:
            this.ivBank = "error";
            this.tvBank = this.$t("IBAN is invalid");
        }
      }

      if (txt.length == 24) {
        if (!this.isValidIbanNumber('IR'+txt)) {
          // شماره شبا نا معتبر
          this.ivBank = "error";
          this.tvBank = this.$t("IBAN is invalid");
        }
      }
    },

    iso7064Mod97_10(iban) {
      var remainder = iban, block;

      while (remainder.length > 2){
        block = remainder.slice(0, 9);
        remainder = parseInt(block, 10) % 97 + remainder.slice(block.length);
      }

      return parseInt(remainder, 10) % 97;
    },

    isValidIbanNumber(str) {
      var pattern = /IR[0-9]{24}/;
      
      if (str.length !== 26) {
        return false;
      }

      if (!pattern.test(str)) {
        return false;
      }

      var newStr = str.substr(4);
      var d1 = str.charCodeAt(0) - 65 + 10;
      var d2 = str.charCodeAt(1) - 65 + 10;
      newStr += d1.toString() + d2.toString() + str.substr(2, 2);
      
      var remainder = this.iso7064Mod97_10(newStr);
      if (remainder !== 1) {
        return false;
      }

      return true;
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  mounted(){
    this.getDoctorInfoByMyMobile();
    this.getDoctorAccountBalance();
  },
  watch: {
    iban (val) {
      this.ibanSubmited = false;
      // console.log(val.length + " ---- " + val + " ---- " + this.iban);
      this.checkIban(val);
      if (val.length > 24) {
        this.iban = val.slice(0,24);
        this.keyIban++;
      }
    }
  },
}
</script>
